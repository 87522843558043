import React from "react"
import loadable from "@loadable/component"
import tw, { styled } from "twin.macro"
import { H1, H2, H3, P, HeaderDivider } from "../components/page-elements"
import Image from "../components/image"
import { CalloutSection } from "../components/shared/callout-section"
import CalloutContent from "../content/callout-section-content.json"
import SEO from "../components/seo"
import { Banner } from "../components/shared/banner"

const Section = loadable(() => import("../components/shared/section"))

const backgroundImage = "glacier-background.jpg"
const Content = {
  meta: {
    title: "About Glacier Painting",
    description: "We are a painting company",
  },
  header: "About us",
  details: {
    src: "family2.jpg",
    alt: "Esther and Carlos",
    title: "Who We Are",
    subTitle: "About Timberman Tree Removal",
    text1:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vitae id quam, adipisci perferendis soluta expedita nam molestias officia reiciendis ad ratione sapiente ut illum, quis atque mollitia, architecto quibusdam porro. Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur dolores exercitationem eaque consequuntur! Obcaecati, molestias esse, voluptates ipsum earum excepturi dolorum ducimus commodi sint, veritatis aliquid possimus eos autem ab?",
  },
  statistics: [
    {
      stat: "43",
      name: "Years Experience",
    },
    {
      stat: "1000+",
      name: "Projects Completed",
    },
    {
      stat: "5/5",
      name: "Review Rating",
    },
  ],
  mission: {
    title: "Our Mission",
    text:
      "We strive to provide fast, honest, and dependable tree services. Tree Removal is our job, but serving our customers is why we are here. We treat our customers like our family.",
  },
  principles: [
    {
      title: "Insured",
      body:
        "We believe in safety first. That is why our company and services are 100% insured.",
      icon: "pine-tree.svg",
      alt: "Hand holding a mobile phone",
    },
    {
      title: "Experienced",
      body: "We have over 20 years of combined experience!",
      icon: "plant.svg",
      alt: "Mobile Phone and Tablet",
    },
    {
      title: "Honest",
      body:
        "With Timberman Tree Removal you are working with honest, down to earth people who love their customers.",
      icon: "thunderbolt.svg",
      alt: "Chemistry flask and female scientist",
    },
  ],
  ourTeam: {
    title: "Our Team",
    members: [
      {
        name: "Tom Glaeser",
        title: "Founder",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi porro optio animi ullam odit autem libero incidunt facere hic fuga.",
        src: "team2.jpg",
        alt: "team",
      },
      {
        name: "Jim McCroy",
        title: "Manager",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi porro optio animi ullam odit autem libero incidunt facere hic fuga.",
        src: "team1.jpg",
        alt: "team",
      },
      {
        name: "Filo",
        title: "Trouble Maker",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi porro optio animi ullam odit autem libero incidunt facere hic fuga.",
        src: "team3.jpg",
        alt: "team",
      },
    ],
  },
}

const AboutPage = () => {
  return (
    <>
      <SEO title={Content.meta.title} description={Content.meta.description} />
      <Banner src={backgroundImage}>
        <Row>
          <InnerH1>{Content.header}</InnerH1>
        </Row>
      </Banner>

      {/* Summary */}
      <Row>
        <Section size="xl">
          <ContentBody>
            <H2 color="darker" className="text-center">
              Trusted in Madison Since 1978
            </H2>
            <HeaderDivider color="primary" className="text-center mx-auto" />
            <ContentText color="darker" className="text-center text-lg">
              Glacier Painting will provide an unparalleled understanding,
              in-depth product knowledge, expertise, and thoughtful customer
              service to give you the best experience with your Madison Home
              Improvement Project, Commercial Painting Project, & or Wood
              Refinishing Project.
            </ContentText>
          </ContentBody>
        </Section>
      </Row>

      {/* Statistics */}
      <Row className="w-full bg-aqua flex justify-between py-4 md:py-0">
        <Statistics>
          {Content.statistics.map(
            ({ stat, name }: { stat: string; name: string }) => (
              <Statistic key={name}>
                <Detail>{stat}</Detail>
                <StatDescription>{name}</StatDescription>
              </Statistic>
            )
          )}
        </Statistics>
      </Row>

      {/* Blocks  */}
      <div className="px-4 py-6 md:px-8 md:py-10 bg-base-secondary-color">
        <Row className="my-4 max-w-screen-xl">
          <HalfCol>
            <StyledImage
              src={"friendly-expertise.jpg"}
              alt={"Painting House"}
            />
          </HalfCol>
          <HalfCol>
            <TextBlock>
              <BlockHeader>Our Mission Statement</BlockHeader>
              <HeaderDivider color="primary" />
              <P color="dark">
                Glacier Painting will provide an unparalleled understanding,
                in-depth product knowledge, expertise, and thoughtful customer
                service to give you the best experience with your Madison Home
                Improvement Project, Commercial Painting Project, & or Wood
                Refinishing Project.
              </P>
            </TextBlock>
          </HalfCol>
        </Row>

        <RowWithReverse className="my-4 max-w-screen-xl">
          <HalfCol>
            <StyledImage src={"top-notch.jpg"} alt={"Commercial Painting"} />
          </HalfCol>
          <HalfCol>
            <TextBlock>
              <BlockHeader>Top-Notch Service</BlockHeader>
              <HeaderDivider color="primary" />
              <P color="dark">
                Our project managers will guide you step-by-step through the
                process and our experienced on-site foremen will ensure that
                your project is completed efficiently so that you have spaces
                you’ll be delighted to spend time in.
              </P>
            </TextBlock>
          </HalfCol>
        </RowWithReverse>
      </div>

      {/* Our Team */}
      {/* <Row className="w-full ">
        <Section size="xl">
          <ContentBody>
            <H2 color="darker" className="text-center md:mb-10">
              {Content.ourTeam.title}
            </H2>
            <HeaderDivider color="primary" className="text-center mx-auto" />
            <ContentText color="darker" className="text-center text-lg">
              Our on-site foremen have years of experience in painting,
              construction, and running a work site efficiently. We also employ
              qualified and reliable painters and workmen who are professional
              and highly skilled.
            </ContentText> */}

      {/* <Galleries>
              {Content.ourTeam.members.map(
                ({ name, title, description, src, alt }) => (
                  <Block key={name}>
                    <FancyImage src={src} alt={alt} />

                    <Overlay />
                    <TextOverlay>
                      <OverlayTitle>{name}</OverlayTitle>
                      <OverlayText color="light">{title}</OverlayText>
                      <Description color="light">{description}</Description>
                    </TextOverlay>
                  </Block>
                )
              )}
            </Galleries>
          </ContentBody>
        </Section>
      </Row> */}

      <CalloutSection
        title={CalloutContent.title}
        text={CalloutContent.text}
        buttonText={CalloutContent.buttonText}
        buttonUrl={CalloutContent.buttonUrl}
      />
    </>
  )
}

const HalfCol: React.FC = ({ children }) => {
  return <div className="w-full md:w-1/2 md:py-4 md:px-6">{children}</div>
}

const Overlay = tw.div`
  bg-primary
  opacity-0
  h-full
  w-full
  absolute
  top-0
  duration-200 
  ease-in-out
`

const OverlayTitle = styled(H3)`
  ${tw`
    text-center
    text-white
    text-2xl
    uppercase
    font-semibold
  `}
`

const TextOverlay = styled.div`
  ${tw`
    absolute
    h-16
    top-0
    bottom-0
    m-auto
    w-full
    opacity-0
    duration-200 
    ease-in-out
    px-4
  `}
  transform: translateY(8px);
`

const OverlayText = styled(P)`
  ${tw`
    text-center
    uppercase
    text-white
  `}
`

const FancyImage = styled(Image)`
  ${tw`
    w-full
  `};
  height: 450px;
`

const Block = styled.div`
  ${tw`
    overflow-hidden
    relative
    flex-none
    md:mx-4
    my-2
    mx-auto
    md:flex-1
    w-full
  `}
  @media (min-width: 768px) {
    max-width: 430px;
  }
  @media (max-width: 768px) {
    ${Overlay} {
      ${tw`
      opacity-75
    `}
    }
    ${FancyImage} {
      ${tw`
        scale-110
      `}
    }
    ${TextOverlay} {
      ${tw`
      h-16
      opacity-100
    `}
      transform: translateY(0);
    }
  }

  &:hover ${Overlay} {
    ${tw`
      opacity-75
    `}
  }

  &:hover ${FancyImage} {
    ${tw`
      scale-110
    `}
  }

  &:hover ${TextOverlay} {
    ${tw`
      h-16
      opacity-100
    `}

    transform: translateY(0);
  }
`

const TextBlock = tw.div`
 flex
 flex-col
 md:py-4
`
const BlockHeader = tw.h2`
  my-4
  md:my-8
  text-4xl 
  font-semibold
`

const Row = tw.div`
  flex
  flex-col
  justify-between
  md:flex-row
  md:mx-auto
  px-page-gutter
  md:px-0
  w-full
`

const RowWithReverse = styled(Row)`
  ${tw`
    md:flex-row-reverse
  
  `}
`

const ContentBody = tw.div`
    w-full
    flex
    flex-col
`

const StyledImage = tw(Image)`
    w-full
`

const ContentText = styled(P)`
  ${tw`text-body md:my-4`}
`

const InnerH1 = styled(H1)`
  ${tw`text-center text-base-color my-8 md:my-16 xl:my-20 mx-auto`}
`

const Statistics = tw.div`
  flex
  flex-wrap
  flex-col
  md:flex-row
  w-full
  max-w-screen-lg
  mx-auto
`

const Statistic = tw.div`
  flex
  flex-row
  md:flex-col
  md:my-8
  mx-auto

  items-center
  md:justify-start
  justify-between
  text-left
  w-full
  md:w-1/3
`

const Detail = tw.div`
  text-accent
  font-black
  text-4xl
  mr-4
  md:mr-0
`

const StatDescription = tw.div`
  font-bold
  text-base-color
  text-lg
`

const Galleries = tw.div`
  flex
  flex-wrap
  w-full
  h-full
  max-w-screen-xl
  mx-auto
  my-4
`

const Description = styled(P)`
  ${tw`text-center`}
`

export default AboutPage
